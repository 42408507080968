.root {
  --grid-top-padding: 60px;
  position: relative;
  height: 100vh;
  min-height: 800px;
  overflow-y: hidden;
}

.blob {
  right: calc(50% - 275px);
  position: fixed;
  top: 0;
  z-index: 1;
  animation: move 1000s ease-in-out infinite;
  transform-origin: 50% 50%;
}

.blob_right {
  position: fixed;
  top: -585px;
  right: calc(50% - 1250px);
  z-index: 1;
  transform: rotate(-15deg);
}

.grid {
  position: relative;
  display: grid;
  grid-template-areas:
    'header   ball'
    'list     ball'
    'cta      ball'
    'swatches lottie';
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  z-index: 10;
  height: 100vh;
}

.container {
  position: relative;
  padding: 0 30px;
}

.header {
  grid-area: header;
  padding-top: var(--grid-top-padding);
}

.header h1 {
  font-size: 48px;
  line-height: 1.15;
}

.list {
  grid-area: list;
  font-weight: 300;
  font-size: 14px;
}

.list ul {
  padding-left: 20px;
}

.list li {
  padding-bottom: 10px;
  line-height: 2;
}

.lottieContainer div {
  height: 150px;
  width: 165px;
}

.cta {
  grid-area: cta;
}

.cta .button {
  border-radius: 50px;
  font-weight: 700;
  padding: 20px 30px;
}

.cat,
.nav,
.location {
  display: none;
}

.stringText,
.ballText {
  display: none;
}

.lottieContainer {
  grid-area: lottie;
}

.swatches {
  flex-direction: row;
  grid-area: swatches;
  display: flex;
  align-items: center;
}

.ball {
  grid-area: ball;
  position: relative;
}

.string {
  content: '';
  width: 10px;
  height: 300px;
  margin: auto;
  border-radius: 0;
}


.circle {
  cursor: pointer;
  border-radius: 50%;
  height: 85px;
  width: 85px;
  position: relative;
  margin: auto;
}

.job_description {
  display: none;
}

@keyframes move {
  0% {
    transform: scale(1) translate(10px, -30px);
  }
  38% {
    transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
  }
  40% {
    transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
  }
  78% {
    transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
  }
  80% {
    transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
  }
  100% {
    transform: scale(1) translate(10px, -30px);
  }
}

@media screen and (min-width: 768px) {
  .grid {
    grid-template-areas:
      'header ball nav'
      'cat lottie swatches'
      'location cta swatches';
    grid-template-columns: 1fr 275px 1fr;
    padding: 0 40px 85px;
    min-height: 800px;
    height: 100%;
  }

  .header h1 {
    font-weight: 300;
    font-size: 60px;
    line-height: 1.26;
    margin: 0;
  }

  .list {
    display: none;
  }

  .job_description {
    display: block;
  }

  .header h6 {
    font-weight: 300;
    font-size: 18px;
    margin: 0;
  }

  .string {
    height: 300px;
  }

  .stringText {
    display: block;
    position: absolute;
    transform: rotate(-90deg);
    top: 145px;
    width: 205px;
  }

  .ballText {
    display: block;
    position: absolute;
    top: 325px;
    right: -75%;
    width: 275px;
  }

  .nav {
    grid-area: nav;
    padding-top: var(--grid-top-padding);
    display: block;
  }

  .menu {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: flex-end;
  }

  .menu li {
    margin: 0 10px 10px 0;
    justify-content: flex-end;
  }

  .menu a {
    text-decoration: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 197.4%;
    opacity: 0.5;
    padding-bottom: 6px;
  }

  .cat {
    grid-area: cat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .arrow {
    position: absolute;
    right: -75px;
    top: 125px;
  }

  .catDescription {
    margin-bottom: 5px;
  }

  .lottieContainer div {
    height: 275px;
    width: 300px;
  }

  .swatches {
    flex-direction: column;
    margin-left: auto;
  }

  .location {
    grid-area: location;
    display: block;
  }

  .locationCurrentlyBased {
    font-weight: 300;
    font-size: 22px;
    line-height: 150%;
  }

  .locationValue {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
  }

  .cta {
    grid-area: cta;
  }

  .cta .button {
    border-radius: 50px;
    font-weight: 700;
    padding: 20px 60px;
  }

  .location,
  .cta,
  .swatches {
    margin-top: auto;
  }
}

@media screen and (min-width: 1200px) {
  .header h1 {
    font-size: 76px;
  }
}

@media screen and (min-width: 1200px) {
  .header h1 {
    font-size: 96px;
  }
}
